import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type GradeResponse = {
  data: any;
  type: string;
};

type GradeState = {
  isLoading: boolean;
  error: any;
  grade: any;
  response: null | GradeResponse;
  gradeList: any[];
  gradeListMeta: ListMeta;
};

const initialState: GradeState = {
  isLoading: false,
  error: false,
  grade: null,
  response: null,
  gradeList: [],
  gradeListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'grade',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    reset(state) {
      state.error = false;
      state.isLoading = false;
      state.response = null;
      state.grade = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getGradeSuccess(state, action) {
      state.isLoading = false;
      state.grade = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_GRADE_SUCCESS'
      };
    },

    // CREATE DATA
    createGradeSuccess(state, action) {
      state.isLoading = false;
      state.grade = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_GRADE_SUCCESS'
      };
    },

    // UPDATE DATA
    updateGradeSuccess(state, action) {
      state.isLoading = false;
      state.grade = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_GRADE_SUCCESS'
      };
    },

    // DELETE DATA
    deleteGradeSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_GRADE_SUCCESS'
      };
    },

    // GET MANAGE DATA
    getGradeListSuccess(state, action) {
      state.isLoading = false;
      state.gradeList = action.payload.data;
      state.gradeListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_GRADE_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

export function getGradeById(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`teacher/grade/${recordId}`);
      // console.log(response);
      console.log('RecordId', recordId);
      dispatch(slice.actions.getGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllGradeList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/grades/all');
      dispatch(slice.actions.getGradeListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGradeList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/grades/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getGradeListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function createGrade(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/grade', payload);
      dispatch(slice.actions.createGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createInputGrade(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/input/grade', payload);
      dispatch(slice.actions.createGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateGrade(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/grade/${recordId}`, payload);
      dispatch(slice.actions.updateGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteGradeById(recordId: number | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/teacher/grade/${recordId}`);
      dispatch(slice.actions.deleteGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
