import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Student, StudentGrade } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type StudentResponse = {
  data: any;
  type: string;
};

type StudentState = {
  isLoading: boolean;
  error: any;
  student: null | Student;
  grade: any;
  gradeList: any[];
  response: null | StudentResponse;
  responses: null | StudentResponse;
  studentList: Student[];
  studentLists: StudentGrade[];

  homework: null;
  studentListMeta: ListMeta;
};

const initialState: StudentState = {
  isLoading: false,
  error: false,
  student: null,
  grade: null,
  homework: null,
  gradeList: [],
  response: null,
  responses: null,
  studentList: [],
  studentLists: [],
  studentListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.student = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    deleteSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_STUDENT_SUCCESS'
      };
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_STUDENT_SUCCESS'
      };
    },

    updateGradeSuccess(state, action) {
      state.isLoading = false;
      state.grade = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_STUDENT_GRADE_SUCCESS'
      };
    },

    createSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_STUDENT_SUCCESS'
      };
    },

    getListSuccess(state, action) {
      state.isLoading = false;
      state.studentList = action.payload.data;
      state.studentListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_LIST_SUCCESS'
      };
    },

    getSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_SUCCESS'
      };
    },

    getGradeSuccess(state, action) {
      state.isLoading = false;
      state.grade = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_GRADE_SUCCESS'
      };
    },

    getAllStudentListSuccess(state, action) {
      state.isLoading = false;
      state.studentList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_ALL_STUDENT_LIST_SUCCESS'
      };
    },
    getAllStudentGradeSuccess(state, action) {
      state.isLoading = false;
      state.studentLists = action.payload.data;
      state.responses = {
        data: action.payload.data,
        type: 'GET_STUDENT_GRADE_SUCCESS'
      };
    },
    getHomeworkDateSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_HOMEWORK_DATE_SUCCESS'
      };
    },
    getGradeStudentListSuccess(state, action) {
      state.isLoading = false;
      state.gradeList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_ALL_STUDENT_GRADE_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;
export const { getAllStudentListSuccess } = slice.actions;

// Actions
// ----------------------------------------------------------------------

export function getStudentByID(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/student/${recordId}`);
      dispatch(slice.actions.getSuccess(response.data));
      console.log(response);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGrade(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/student/grade/${recordId}`);
      dispatch(slice.actions.getGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/students/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
export function getStudentNameList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/get/name');
      dispatch(slice.actions.getAllStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
export function getGradePoinData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/get/homeworkdata');
      dispatch(slice.actions.getAllStudentGradeSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
export function getAllStudentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/students/get/all');
      dispatch(slice.actions.getAllStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getStudentGradePoint() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/get/grade');
      dispatch(slice.actions.getGradeStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getStudentGradeList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/students/get/grade/all');
      dispatch(slice.actions.getGradeStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getAllStudentListForTeacher() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/students/get/all/students');
      dispatch(slice.actions.getAllStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
export function getHomeworkDates() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/get/homework');

      dispatch(slice.actions.getHomeworkDateSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
export function getAllStudentListForDirector() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/director/get/all/students');
      dispatch(slice.actions.getAllStudentListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteStudent(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/student/${recordId}`);
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteHomeworkPoint() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/student/homework/delete');
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateStudent(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/student/${recordId}`, payload);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGrade(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/student/grade/${recordId}`, payload);
      dispatch(slice.actions.updateGradeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createStudent(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/student/`, payload);
      dispatch(slice.actions.createSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
