import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  FormHelperText,
  TextField,
  Button,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik, FormikState } from 'formik';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { createHomework } from '../../../redux/slices/homework';
import dayjs from 'dayjs';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 360;

type HomeworkFormProps = {
  openForm: boolean;
  formClosed: VoidFunction;
  processedSuccess: VoidFunction;
  dates: string;
};

type Questions = {
  question: string;
}[];

export default function HomeworkForm({
  openForm,
  formClosed,
  processedSuccess,
  dates
}: HomeworkFormProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { response: homeworkResponse } = useSelector((state: RootState) => state.homework);

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  useEffect(() => {
    if (homeworkResponse && homeworkResponse.type === 'CREATE_HOMEWORK_SUCCESS') {
      enqueueSnackbar('Create success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      processedSuccess();
      handleClose();
    }
  }, [homeworkResponse, enqueueSnackbar]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formClosed();
  };

  const CreateHomeworkSchema = Yup.object().shape({
    title: Yup.string().required('Number of questions is required'),
    date: Yup.string().required('Date is requires'),
    point: Yup.number().typeError('Point must be a number').required('Point is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      date: dates,
      questions: [] as Questions,
      point: ''
    },
    validationSchema: CreateHomeworkSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let formData = new FormData();
        formData.append(`title`, values.title);
        formData.append(`point`, values.point);
        formData.append(`totalQuestions`, values.questions.length.toString());
        formData.append(`date`, dayjs(values.date).format('YYYY-MM-DD'));
        values.questions.map((e, index) => {
          formData.append(`question${index}`, e.question);
        });
        await dispatch(createHomework(formData));
      } catch (error: any) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />

        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              overflow: 'hidden',
              borderRadius: 0,
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">New Homework</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <Stack spacing={1.5}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Title"
                      variant="filled"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Point"
                      variant="filled"
                      type="number"
                      {...getFieldProps('point')}
                      error={Boolean(touched.point && errors.point)}
                      helperText={touched.point && errors.point}
                    />
                  </Stack>

                  {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        value={touched.date}
                        onChange={(newValue) => setFieldValue('date', newValue)}
                      />
                      <Typography>{dates}</Typography>
                    </LocalizationProvider>
                  </Stack> */}
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Date"
                      variant="filled"
                      value={dates}
                      error={Boolean(touched.date && errors.date)}
                      InputProps={{ readOnly: true }}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    {touched.date && errors.date && (
                      <FormHelperText error={true}>{errors.date}</FormHelperText>
                    )}
                  </Stack>

                  {values.questions.map((e, index) => (
                    <>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 3, sm: 2 }}
                        key={`${index}1`}
                      >
                        <TextField
                          fullWidth
                          label={`Question ${index + 1}`}
                          variant="filled"
                          value={values.questions[index].question}
                          onChange={(newValue) => {
                            setFieldValue(
                              'questions',
                              values.questions.map((e, i) => {
                                return i === index ? { ...e, question: newValue.target.value } : e;
                              })
                            );
                          }}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Button
                          fullWidth
                          size="large"
                          type="button"
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setFieldValue(
                              'questions',
                              values.questions.filter((e, i) => {
                                return i !== index;
                              })
                            );
                          }}
                        >
                          Remove Question
                        </Button>
                      </Stack>
                    </>
                  ))}
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => {
                        setFieldValue('questions', [
                          ...values.questions,
                          {
                            question: ''
                          }
                        ]);
                      }}
                    >
                      Add Question
                    </Button>
                  </Stack>
                </Stack>

                <Stack spacing={1.5}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Add New Homework
                  </LoadingButton>
                </Stack>
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
