import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ListMeta, ListQueryParams } from '../../@types/table';
import { Directors } from '../../@types/school';

// ----------------------------------------------------------------------

type DirectorResponse = {
  data: any;
  type: string;
};

type DirectorState = {
  isLoading: boolean;
  error: any;
  directors: null | Directors;
  response: null | DirectorResponse;
  DirectorList: Directors[];
  directorListMeta: ListMeta;
};

const initialState: DirectorState = {
  isLoading: false,
  error: false,
  directors: null,
  response: null,
  DirectorList: [],
  directorListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'DirectorDashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.directors = null;
    },
    createSuccess(state, action) {
      state.isLoading = false;
      state.directors = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_DIRECTOR_SUCCESSS'
      };
      console.log(state.response);
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.directors = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_DIRECTOR_DASHBOARD'
      };
    },
    getSettingSuccess(state, action) {
      state.isLoading = false;
      state.directors = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_DIRECTOR_DASHBOARD'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function createDirectorDashboard(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/director/dashboard/post`, payload);

      dispatch(slice.actions.createSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDirectorDashboard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('director/dashboard/get');
      dispatch(slice.actions.getSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDirectorSetting() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('director/settings/get');
      dispatch(slice.actions.getSettingSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
