import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import ProviderLayout from '../layouts/provider';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import ProviderGuestGuard from '../guards/ProviderGuestGuard';
import ProviderAuthGuard from '../guards/ProviderAuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Roles } from '../constants/roles';
import { Modules } from '../constants/modules';
import { GuestProvider } from 'contexts/GuestProviderContext';
import ModuleBasedAuthGuard from 'guards/ModuleBasedAuthGuard';
import ModuleBasedGuestGuard from 'guards/ModuleBasedGuestGuard';
import DirectorDashboardList from 'pages/dashboard/school/DirectorDashboardList';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: 'admin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AdminLogin />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'agency',
          children: [
            { element: <Navigate to="/dashboard/agency/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.ADMIN]}>
                  <AgencyList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':agencyId/provider',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.ADMIN]}>
                  <ProviderList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'provider',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ProviderList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'provider/:providerId/rates',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ProviderRateList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'provider/:providerId/zip-codes',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.ZIPCODE_MODULE}>
                <ProviderZipCodeList />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'position',
          children: [
            { element: <Navigate to="/dashboard/position/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <PositionList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'service-status',
          children: [
            { element: <Navigate to="/dashboard/service-status/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <ServiceStatusList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'calendar',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                <HolidayList />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'news',
          children: [
            { element: <Navigate to="/dashboard/news/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsForm />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: ':editNewsId/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsForm />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'bonus',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.BONUS_MODULE}>
                <BonusForm />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'billing',
          children: [
            { element: <Navigate to="/dashboard/billing/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <BillingList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'director',
          children: [
            { element: <Navigate to="/dashboard/director/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.ADMIN]}>
                  <DirectorList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'class',
          children: [
            { element: <Navigate to="/dashboard/class/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <ClassList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'class/grading',
          children: [
            { element: <Navigate to="/dashboard/class/grading/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <ClassGradingList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'teacher',
          children: [
            { element: <Navigate to="/dashboard/teacher/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <TeacherList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'director/billing',
          children: [
            { element: <Navigate to="/dashboard/director/billing/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <DirectorBillingList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'director/news',
          children: [
            { element: <Navigate to="/dashboard/director/news/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <DirectorNewsList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <DirectorNewsForm />
                </RoleBasedGuard>
              )
            },
            {
              path: ':editNewsId/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
                  <DirectorNewsForm />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'student',
          children: [
            { element: <Navigate to="/dashboard/student/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
                  <StudentList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'home',
          children: [
            { element: <Navigate to="/dashboard/home/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
                  <DirectorDashboardList></DirectorDashboardList>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'teacher/news',
          children: [
            { element: <Navigate to="/teacher/news/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
                  <TeacherNewsList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
                  <TeacherNewsForm />
                </RoleBasedGuard>
              )
            },
            {
              path: ':editNewsId/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
                  <TeacherNewsForm />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user/news',
          children: [
            { element: <Navigate to="/user/news" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER, Roles.STUDENT]}>
                  <UserNewsGallery />
                </RoleBasedGuard>
              )
            },
            {
              path: 'view/:newsId',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.TEACHER, Roles.STUDENT]}>
                  <UserNewsView />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'teacher/:teacherId/rates',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.DIRECTOR]}>
              <TeacherRateList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'teacher/homework/list',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
              <Homework />
            </RoleBasedGuard>
          )
        },
        {
          path: 'teacher/homework/submission/list',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
              <HomeworkSubmission />
            </RoleBasedGuard>
          )
        },
        {
          path: 'teacher/grade',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
              <Grade />
            </RoleBasedGuard>
          )
        },
        {
          path: 'teacher/billing',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.TEACHER]}>
              <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                <TeacherBillingList />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'student/news',
          children: [
            { element: <Navigate to="/student/news/view" replace /> },
            {
              path: 'view',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.STUDENT]}>
                  <StudentNewsGallery />
                </RoleBasedGuard>
              )
            },
            {
              path: 'view/:newsId',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.STUDENT]}>
                  <StudentNewsView />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'student/grade',
          children: [
            { element: <Navigate to="/student/grade/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.STUDENT]}>
                  <GradeList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'student/homework',
          children: [
            { element: <Navigate to="/student/homework/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.STUDENT]}>
                  <StudentHomeworkList />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Guest Routes
    {
      path: 'provider',
      children: [
        {
          path: 'home',
          element: (
            <GuestProvider>
              <ProviderAuthGuard>
                <ModuleBasedGuestGuard moduleName={Modules.CLEARANCE_MODULE}>
                  <ProviderHome />
                </ModuleBasedGuestGuard>
              </ProviderAuthGuard>
            </GuestProvider>
          )
        },
        {
          path: 'h',
          element: (
            <GuestProvider>
              <ProviderAuthGuard>
                <ProviderLayout />
              </ProviderAuthGuard>
            </GuestProvider>
          ),
          children: [
            {
              path: 'clearances',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.CLEARANCE_MODULE}>
                  <ClearanceList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'news',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.NEWS_MODULE}>
                  <NewsGallery />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'news/:newsId',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.NEWS_MODULE}>
                  <NewsView />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'billing',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.BILLING_MODULE}>
                  <GuestBillingList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'my-schedule',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.BILLING_MODULE}>
                  <MySchedule />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'my-zip-codes',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.ZIPCODE_MODULE}>
                  <ZipCodeList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'email',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.ZIPCODE_MODULE}>
                  <Email />
                </ModuleBasedGuestGuard>
              )
            }
          ]
        }
      ]
    },
    {
      path: 'find-provider/:qrCode',
      element: (
        <GuestProvider>
          <ProviderGuestGuard>
            <FindProviderByCode />
          </ProviderGuestGuard>
        </GuestProvider>
      )
    },
    {
      path: 'find-teacher/:qrCode',
      element: (
        <GuestGuard>
          <FindTeacherByCode />
        </GuestGuard>
      )
    },
    {
      path: 'news/:newsSlug',
      element: <NewsView />
    },
    {
      path: 'rs/:token',
      element: <SessionView />
    },
    {
      path: 'schedule/:token',
      element: <SharedSchedule />
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    // { path: '*', element: <Navigate to="/404" replace /> }
    { path: '/', element: <Navigate to="/auth/login" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const AdminLogin = Loadable(lazy(() => import('../pages/authentication/AdminLogin')));
// const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
// Agency
const AgencyList = Loadable(lazy(() => import('../pages/dashboard/AgencyList')));
// Position
const PositionList = Loadable(lazy(() => import('../pages/dashboard/PositionList')));
// Service Status
const ServiceStatusList = Loadable(lazy(() => import('../pages/dashboard/ServiceStatusList')));
// Holiday
const HolidayList = Loadable(lazy(() => import('../pages/dashboard/HolidayList')));
// Billing
const BillingList = Loadable(lazy(() => import('../pages/dashboard/agency/BillingList')));
// Billing
const DirectorBillingList = Loadable(lazy(() => import('../pages/dashboard/director/BillingList')));

const DirectorNewsList = Loadable(lazy(() => import('../pages/dashboard/director/NewsList')));
const DirectorNewsForm = Loadable(lazy(() => import('../pages/dashboard/DirectorNewsForm')));
// Provider
const ProviderList = Loadable(lazy(() => import('../pages/dashboard/ProviderList')));
// Provider Rate
const ProviderRateList = Loadable(
  lazy(() => import('../pages/dashboard/provider/ProviderRateList'))
);
// Teacher Rate
const TeacherRateList = Loadable(lazy(() => import('../pages/dashboard/teacher/TeacherRateList')));
// News
const NewsForm = Loadable(lazy(() => import('../pages/dashboard/NewsForm')));
const NewsList = Loadable(lazy(() => import('../pages/dashboard/NewsList')));
const TeacherNewsForm = Loadable(lazy(() => import('../pages/dashboard/TeacherNewsForm')));
const TeacherNewsList = Loadable(lazy(() => import('../pages/dashboard/TeacherNewsList')));
// News
const NewsGallery = Loadable(lazy(() => import('../pages/provider-dashboard/NewsGallery')));
const NewsView = Loadable(lazy(() => import('../pages/provider-dashboard/NewsView')));
const StudentNewsGallery = Loadable(lazy(() => import('../pages/dashboard/StudentNewsGallery')));
const StudentNewsView = Loadable(lazy(() => import('../pages/dashboard/StudentNewsView')));
// Billing
const GuestBillingList = Loadable(
  lazy(() => import('../pages/provider-dashboard/billing/BillingList'))
);
// Bonus
const BonusForm = Loadable(lazy(() => import('../pages/dashboard/BonusForm')));
// Main
const FindProviderByCode = Loadable(
  lazy(() => import('../pages/provider-dashboard/FindProviderByCode'))
);
// Main
const FindTeacherByCode = Loadable(lazy(() => import('../pages/dashboard/FindTeacherByCode')));
// Provider Home
const ProviderHome = Loadable(lazy(() => import('../pages/provider-dashboard/ProviderHome')));
const ClearanceList = Loadable(lazy(() => import('../pages/provider-dashboard/ClearanceList')));
const MySchedule = Loadable(lazy(() => import('../pages/provider-dashboard/MySchedule')));
const ZipCodeList = Loadable(lazy(() => import('../pages/provider-dashboard/ZipCodeList')));
const ProviderZipCodeList = Loadable(
  lazy(() => import('../pages/dashboard/provider/ProviderZipCodeList'))
);
const Email = Loadable(lazy(() => import('../pages/provider-dashboard/Email')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Guest
const SessionView = Loadable(lazy(() => import('../pages/guest/SessionView')));
const SharedSchedule = Loadable(lazy(() => import('../pages/shared-schedule/SharedSchedule')));
// **** SCHOOL MODULE ****
// Director
const DirectorList = Loadable(lazy(() => import('../pages/dashboard/school/DirectorList')));
// Director Class Grading
const ClassList = Loadable(lazy(() => import('../pages/dashboard/school/ClassList')));
// Class
const ClassGradingList = Loadable(lazy(() => import('../pages/dashboard/director/ClassGradeList')));
// Teacher
const TeacherList = Loadable(lazy(() => import('../pages/dashboard/school/TeacherList')));
// Student
const StudentList = Loadable(lazy(() => import('../pages/dashboard/school/StudentList')));
const GradeList = Loadable(lazy(() => import('../pages/dashboard/school/GradeList')));
const StudentHomeworkList = Loadable(lazy(() => import('../pages/dashboard/StudentHomeworkList')));
const Homework = Loadable(lazy(() => import('../pages/dashboard/teacher/Homework')));
const UserNewsGallery = Loadable(lazy(() => import('../pages/dashboard/user/UserNewsGallery')));
const UserNewsView = Loadable(lazy(() => import('../pages/dashboard/user/UserNewsView')));
const HomeworkSubmission = Loadable(
  lazy(() => import('../pages/dashboard/teacher/HomeworkSubmission'))
);
const Grade = Loadable(lazy(() => import('../pages/dashboard/teacher/Grade')));
// Billing
const TeacherBillingList = Loadable(
  lazy(() => import('../pages/dashboard/teacher/billing/BillingList'))
);
