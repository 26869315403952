import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type HomeworkResponse = {
  data: any;
  type: string;
};

type HomeworkState = {
  isLoading: boolean;
  error: any;
  homework: any;
  recurring: any;
  recurringDays: any[];
  untilDate: any;
  response: null | HomeworkResponse;
  submission: any[];
  homeSubmission: any[];
  homeworkList: any[];
  homeworkListMeta: ListMeta;
  absentCount: number;
  sumOfPoint: number;
  totalOfPoint: number;
  rank: string;
};

const initialState: HomeworkState = {
  isLoading: false,
  error: false,
  homework: null,
  recurring: null,
  recurringDays: [],
  untilDate: null,
  response: null,
  submission: [],
  homeSubmission: [],
  homeworkList: [],
  homeworkListMeta: {} as ListMeta,
  absentCount: 0,
  sumOfPoint: 0,
  totalOfPoint: 0,
  rank: ''
};

const slice = createSlice({
  name: 'homework',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.homework = null;
      state.recurring = null;
      state.recurringDays = [];
      state.untilDate = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_HOMEWORK_SUCCESS'
      };
    },
    // getHomeworkDateSuccess(state, action) {
    //   state.isLoading = false;
    //   state.homework = action.payload.data;
    //   state.response = {
    //     data: action.payload.success,
    //     type: 'GET_HOMEWORK_DATE_SUCCESS'
    //   };
    // },
    getHomeworkSubmissionSuccess(state, action) {
      state.isLoading = false;
      state.submission = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_HOMEWORK_SUBMISSION_SUCCESS'
      };
    },

    getStudentHomeworkSubmissionSuccess(state, action) {
      state.isLoading = false;
      state.homeSubmission = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_STUDENT_HOMEWORK_SUBMISSION_SUCCESS'
      };
    },

    // CREATE DATA
    createHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_HOMEWORK_SUCCESS'
      };
    },

    // UPDATE DATA
    updateHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_HOMEWORK_SUCCESS'
      };
    },
    duplicateHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'DUPLICATE_HOMEWORK_SUCCESS'
      };
    },
    // RECURRING HOMEWORK DATA
    recurringHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.recurring = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'RECURRING_HOMEWORK_SUCCESS'
      };
    },

    // RECURRING HOMEWORK DATA
    recurringGetHomeworkSuccess(state, action) {
      state.isLoading = false;
      // Assuming all records have the same `until_date`
      const recurringHomework = action.payload.data;
      const untilDate = recurringHomework.length > 0 ? recurringHomework[0].until_date : null;
      const recurringDays = recurringHomework.map((item: any) => item.recurring_day);

      state.recurringDays = recurringDays;
      state.untilDate = untilDate;

      state.response = {
        data: action.payload.data,
        type: 'GET_RECURRING_HOMEWORK_SUCCESS'
      };
    },

    // DELETE DATA
    deleteHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_HOMEWORK_SUCCESS'
      };
    },

    // GET MANAGE DATA
    getHomeworkListSuccess(state, action) {
      state.isLoading = false;
      state.homeworkList = action.payload.data;
      state.homeworkListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_HOMEWORK_LIST_SUCCESS'
      };
    },

    // UPDATE DATA
    submitHomeworkSuccess(state, action) {
      state.isLoading = false;
      state.homework = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'SUBMIT_HOMEWORK_SUCCESS'
      };
    },

    getStudentHomeworkListSuccess(state, action) {
      state.isLoading = false;
      state.homeworkList = action.payload.data;
      state.homeworkListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_HOMEWORK_LIST_SUCCESS'
      };
    },
    getStudentCountSuccess(state, action) {
      state.isLoading = false;
      state.absentCount = action.payload.data.studentData.absentCount;
      state.sumOfPoint = action.payload.data.studentData.total_points;
      state.totalOfPoint = action.payload.data.studentData.max_total_points;
      state.rank = action.payload.data.studentData.rank;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_HOMEWORK_ABSENT_COUNT_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getHomeworkById(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`teacher/homework/${recordId}`);
      dispatch(slice.actions.getHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllHomeworkSubmission(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/homeworks/submission/${recordId}`);
      dispatch(slice.actions.getHomeworkSubmissionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentHomeworkSubmission(homeworkId: number, studentId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/homeworks/submission/${homeworkId}/${studentId}`);
      dispatch(slice.actions.getStudentHomeworkSubmissionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHomeworkByIdForStudent(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`student/homework/${recordId}`);
      dispatch(slice.actions.getHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createHomework(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/homework', payload);
      dispatch(slice.actions.createHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateHomework(recordId: number | null, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/homework/${recordId}`, payload);
      dispatch(slice.actions.updateHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function duplicateHomework(recordId: number | null, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/duplicate/homework/${recordId}`, payload);
      dispatch(slice.actions.duplicateHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getduplicateHomework(recordId: number | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/get/duplicate/homework/${recordId}`);
      dispatch(slice.actions.duplicateHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function recurringHomework(recordId: number | null, untilDate: any, days: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/homework/recurring/${recordId}`, {
        days,
        untilDate
      });
      dispatch(slice.actions.recurringHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRecurringHomework(recordId: number | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/homework/recurring/${recordId}`);
      dispatch(slice.actions.recurringGetHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllHomeworkList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/homeworks/all');
      dispatch(slice.actions.getHomeworkListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHomeworkList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/homeworks/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getHomeworkListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteHomeworkById(recordId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/teacher/homework/${recordId}`);
      dispatch(slice.actions.deleteHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitHomework(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/student/homework/${recordId}`, payload);
      dispatch(slice.actions.submitHomeworkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentHomeworkList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/homeworks/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getStudentHomeworkListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentHomeworkCount() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/homeworks/count');
      dispatch(slice.actions.getStudentCountSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
